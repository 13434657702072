import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-size: cover;
  background-position: center center;
  background-image: url('https://lqn-assets-public.s3-us-west-2.amazonaws.com/background-arras.jpg');
`;

export const Header = styled.header`
  padding-top: 5%;
  display: flex;
  justify-content: center;

  div {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid #fff;
    box-shadow: #4a4a4a 0 0 6px -2px;

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const Content = styled.section`
  width: 60%;
  margin: 5% auto;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const Box = styled.div`
  width: 100%;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: #4a4a4a 0 0 6px -2px;
  padding: 20px;
  text-align: center;
`;
