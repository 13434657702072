import { notification } from 'antd';
import errorDict from './Errors';
import { lqnAssets } from 'services/Const';

const marval_img = `${lqnAssets}logo-marval.jpg`;

export const resolveNodes = list => {
  if (list) {
    const { edges } = list;
    if (edges) {
      const nodes = [];
      edges.map(edge => nodes.push(edge.node));
      return nodes;
    }
    return list;
  }
  return [];
};

export const notify = ({ type = 'success', message, description }) => {
  notification[type]({
    message: message,
    description: description
  });
};

export const validateGQLResponse = (
  gql,
  errorFn,
  okFn,
  hideNotification = false
) => {
  if (gql && gql.error) {
    const { errors } = gql;
    if (!hideNotification) {
      notify({
        type: 'error',
        message: errorDict[errors].title,
        description: errorDict[errors].message
      });
    }
    return errorFn(errors);
  }
  return okFn();
};

export const groupBy = (xs, f) =>
  xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});

export const isEmpty = object => {
  delete object['__typename'];
  return Object.values(object).every(x => x === null || x === '');
};

export const getCompanyImage = companyDomain => {
  const companies = {
    marval: marval_img
  };
  return companies[companyDomain];
};
