export const lqnAssets =
  'https://lqn-assets-public.s3-us-west-2.amazonaws.com/';

export const validRadicationCities = ['Bogotá', 'Cali', 'Bucaramanga', 'Barranquilla', 'Cartagena'];

export const identificationType = [
  {
    value: 'CC',
    name: 'Cédula de ciudadania'
  },
  {
    value: 'CE',
      name: 'Cédula de extranjería'
  },
  {
    value: 'PASSPORT',
      name: 'Pasaporte'
  },
  {
    value: 'NIT',
      name: 'Nit'
  }
];

export const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

